import { Text } from '@renderer-ui-library/atoms'
import {
  BlockTopText,
  ButtonGroup,
  ElementsWrapper,
  Media,
} from '@renderer-ui-library/molecules'
import { BlockWrapper } from '@renderer-ui-library/scaffolding/BlockWrapper/BlockWrapper'
import { BlockProps } from 'blocks/BlockProps'
import classnames from 'classnames'
import React from 'react'
import { ITextImageSplitBlockData } from './ITextImageSplitBlockData'
import styles from './textImageSplitBlock.module.scss'

export const TextImageSplitBlock: React.FC<
  BlockProps<ITextImageSplitBlockData>
> = React.memo((props) => (
  <BlockWrapper blockData={props.data}>
    {(props.data.topText || props.data.topTitle) && (
      <BlockTopText
        title={props.data.topTitle}
        text={props.data.topText}
        highlight={props.data.highlight}
      />
    )}
    <div
      className={classnames(styles.wrapper, {
        [styles.imageRight]: props.data.orientation === 'imageRight',
      })}
    >
      <div className={styles.mediaWrapper}>
        {props.data.media && (
          <div
            className={classnames(styles.media, {
              [styles.highlight]: props.data.highlight,
              [styles.noBorder]: !props.data.imageBorder,
            })}
          >
            <Media media={props.data.media} ratio={4 / 3} />
          </div>
        )}
      </div>
      <div className={styles.contentWrapper}>
        <ElementsWrapper>
          <Text
            text={props.data.text}
            highlight={props.data.highlight}
            color={props.data.highlight ? 'bright' : 'dark'}
          />
          <ButtonGroup
            highlight={props.data.highlight}
            primaryButton={props.data.buttonPrimary}
            secondaryButton={props.data.buttonSecondary}
          />
        </ElementsWrapper>
      </div>
    </div>
  </BlockWrapper>
))

TextImageSplitBlock.displayName = 'TextImageSplitBlock'
